<template>
  <div id="dateRange" v-show="dashboardVisible">
    <div class="dateRangeCover" @click="hide()"></div>
    <div class="dateRange element materialShadow">
      <b>{{ x("statPerPickerSettings") }}</b><br>
      {{ x("statPerPickerHeadline") }}

      <dl>
        <dt>{{ x("statPerPickerUserType") }}</dt>
        <dd v-for="userRole in allUserRoles" :key="userRole">
          <input type="checkbox" :id="userRole" name="userRole"
                 :value="userRole" v-model="selectedUserRoles">{{ x(userRole) }}
        </dd>

        <dt>{{ x("channels") }}</dt>
        <dd v-for="channel in allChannels" :key="channel.id">
          <input type="checkbox" :id="'channel_'+channel.id"
                 :value="channel.name" v-model="selectedChannels">
          <label :for="'channel_'+channel.id">{{channel.name}}</label>
        </dd>

        <dt>Periode</dt>
        <dd>
          <input type="radio" id="dateRange_period_7"
                 name="dateRange_period" value="7" v-model="selectedDays">
          <label for="dateRange_period_7">{{ x("statPerPickerLast7Days") }}</label>
        </dd>
        <dd>
          <input type="radio" id="dateRange_period_31"
                 name="dateRange_period" value="31" v-model="selectedDays">
          <label for="dateRange_period_31">{{ x("statPerPickerLast31Days") }}</label>
        </dd>
        <dd>
          <input type="radio" id="dateRange_period_365"
                 name="dateRange_period" value="365" v-model="selectedDays">
          <label for="dateRange_period_365">{{ x("statPerPickerLast12Months") }}</label>
        </dd>
        <dd>
          <input type="radio" id="dateRange_period_10000" name="dateRange_period" value="10000"
                 v-model="selectedDays">
          <label for="dateRange_period_10000">{{ x("statPerPickerLast10000Days") }}</label>
        </dd>
      </dl>

      <button class="lsc-button lsc-rounded Theme_C3_BG Theme_C3_TXT Theme_C3_Border"
              @click="updateOptions()">
        {{ x("statPerPickerGetStats")}}
      </button>
      <button class="lsc-button lsc-rounded Theme_C4_BG Theme_C4_TXT Theme_C4_Border"
              @click="hide()">
        {{ x("statPerPickerClose")}}
      </button>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import translate from '../Mixins/Translate';
  import { httpGet } from '../../classes/httpHelper';

  export default {
    name: 'dashboard-options',
    mixins: [translate],
    data() {
      return {
        allUserRoles: ['internal', 'external'],
        selectedUserRoles: ['internal', 'external'],
        selectedChannels: [],
        selectedDays: 31,
      };
    },
    computed: {
      allChannels() {
        return this.channelsCache || [];
      },
      ...mapState(['config', 'user', 'dashboardOptions', 'accessToken', 'dashboardVisible']),
      ...mapState('CachingStore', {
        channelsCache: (state) => state.channels,
      }),
    },
    watch: {
      dashboardVisible(isVisible) {
        if (!isVisible) return;
        this.selectedDays = this.dashboardOptions.days;
        this.selectedUserRoles = this.dashboardOptions.userRoles;
        this.selectedChannels = this.dashboardOptions.selectedChannels;
        this.$nextTick(() => {
          const p = $('.changeDate');
          if (!p) {
            return;
          }

          const position = p.offset();
          $('.dateRange')
            .css('top', `${position.top}px`)
            .css('left', `${position.left}px`);
        });
      },
      dashboardOptions(newValue) {
        this.selectedDays = newValue.days;
        this.selectedUserRoles = [...newValue.userRoles];
        this.selectedChannels = [...newValue.selectedChannels];
      },
    },
    async mounted() {
      await this.getChannels();
    },
    methods: {
      async getChannels() {
        if (this.channelsCache === null) {
          const channels = await httpGet('channels');
          const channelNames = channels.map((channel) => channel.name);
          this.$store.commit('setDashboardOptions', { selectedChannels: channelNames });
          this.$store.commit('CachingStore/setChannelCache', channels);
        }
      },

      updateOptions() {
        this.$store.commit('setDashboardOptions', {
          days: this.selectedDays,
          selectedChannels: this.selectedChannels,
          userRoles: this.selectedUserRoles,
        });
        this.hide();
      },

      show() {
        this.$store.commit('setDashboardVisible', true);
      },

      hide() {
        this.$store.commit('setDashboardVisible', false);
      },
    },
  };

</script>
